import type {Buffer} from './types';

export const createBuffer = <T>(size: number): Buffer<T> => {
    const buffer = new Array<T>();

    return {
        [Symbol.iterator]: () => buffer[Symbol.iterator](),
        get size() {
            return buffer.length;
        },
        add: value => {
            if (size === 0) {
                return;
            }
            if (buffer.length < size) {
                buffer.splice(size, 0, value);
            } else {
                buffer.shift();
                buffer.push(value);
            }
        },
        clear: () => {
            buffer.length = 0;
        },
        entries: () => buffer.entries(),
    };
};
