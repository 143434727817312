import React from 'react';
import cx from 'classnames';

import styles from './InteractiveElement.module.scss';

export type InteractiveElementHTMLTag = 'button' | 'a' | 'div' | 'summary';

const isAnchor = (
    t: React.ComponentProps<InteractiveElementHTMLTag>,
): t is React.ComponentProps<'a'> =>
    'href' in t && Boolean(t.href) && t.href !== '#';

const InteractiveElementContent = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
    InteractiveElementProps
>(({children, className, disabled, htmlTag = 'button', ...props}, ref) => {
    return React.createElement(
        !disabled ? (isAnchor(props) ? 'a' : htmlTag) : 'button',
        {
            ref,
            className: cx(styles.el, className),
            disabled,
            ...props,
        },
        children,
    );
});

InteractiveElementContent.displayName = 'InteractiveElementContent';

export const InteractiveElement: React.FC<
    React.ComponentPropsWithoutRef<InteractiveElementHTMLTag> & {
        className?: string;
        disabled?: boolean;
        htmlTag?: InteractiveElementHTMLTag;
        href?: string;
    }
> = ({...props}) => {
    return <InteractiveElementContent {...props} />;
};

export const RefInteractiveElement = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
    InteractiveElementProps
>(({...props}, ref) => {
    return <InteractiveElementContent {...props} ref={ref} />;
});

RefInteractiveElement.displayName = 'InteractiveElementContent';

export type InteractiveElementProps = React.ComponentProps<
    typeof InteractiveElement
>;
