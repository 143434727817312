import type {Signal, SignalVariant} from '@pexip/signal';
import {createSignal} from '@pexip/signal';
import type {IndexedDevices} from '@pexip/media-control';

import type {MediaSignals, MediaSignalsOptional} from './types';

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param crucial - Signify if the signal is unmissable. @defaultValue true
 * @param variant - The variant of the signal @see Signal @defaultValue 'generic'
 */
export const createMediaSignal = <T = undefined>(
    name: string,
    crucial = true,
    // FIXME: need `schedule` function to support `batched` variant
    variant: Exclude<SignalVariant, 'batched'> = 'generic',
) =>
    createSignal<T>({
        name: `media/${name}`,
        allowEmittingWithoutObserver: !crucial,
        variant,
    });

export const REQUIRED_SIGNAL_KEYS = [
    'onMediaChanged',
    'onVAD',
    'onSilentDetected',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for media to work
 *
 * @param more - Keys from `MediaSignalsOptional`, @see MediaSignalsOptional
 * @param scope - any scope prefix for the generated signal name, @see Signal
 *
 * The following signals created by default
 * - 'onMediaChanged',
 * - 'onVAD',
 *
 * @see REQUIRED_SIGNAL_KEYS
 */
export const createMediaSignals = <K extends keyof MediaSignalsOptional>(
    more: K[],
    scope = '',
) => {
    const signalScope = scope && [scope, ':'].join('');
    type SignalKeys =
        | (typeof more)[number]
        | (typeof REQUIRED_SIGNAL_KEYS)[number];
    return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => {
            // @ts-expect-error
            signals[key] = createMediaSignal<
                MediaSignals[typeof key] extends Signal<infer S> ? S : never
            >(`${signalScope}${key}`);
            return signals;
        },
        {} as Pick<Required<MediaSignals>, SignalKeys>,
    );
};

export type GeneratedMediaSignals<K extends keyof MediaSignals> = Pick<
    Required<MediaSignals>,
    K
>;

/**
 * Internal signals
 * @internal
 */
export const internalSignals = {
    onDevicesChanged: createSignal<IndexedDevices>({
        name: 'media/devicesChanged',
    }),
};
