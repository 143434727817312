/**
 * An implementation of {@link https://en.wikipedia.org/wiki/Signals_and_slots | Signals and slots}.
 *
 * @packageDocumentation
 */

import {logger, flags} from './logger';
import {createBuffer} from './buffer';
import type {
    Buffer,
    Observer,
    Signal,
    SignalOptions,
    GenericSignalOptions,
    BehaviorSignalOptions,
    ReplaySignalOptions,
    BatchedSignalOptions,
} from './types';

const NAMELESS_OBSERVER = 'Anonymous';
const NAMELESS_SIGNAL = 'AnonymousSignal';
const DEFAULT_BUFFER_SIZE = 2;

function getName<T>(observer: Observer<T> | Observer<T[]>) {
    return observer.name || NAMELESS_OBSERVER;
}

/**
 * A function to create a new {@link Signal}
 *
 * @param options - See {@link SignalOptions}
 *
 * @example
 *
 * ```typescript
 * const defaultSignal = createSignal<number>();
 *
 * // Behavior Signal, always emitted with the latest value when added
 * const behaviorSignal = createSignal<number>({variant: 'behavior'});
 *
 * // Replay Signal, replay the last values when added according to `bufferSize`
 * const replaySignal = createSignal<number>({variant: 'replay'});
 * ```
 */
export function createSignal<T = undefined>(
    options?: GenericSignalOptions,
): Signal<T>;
export function createSignal<T = undefined>(
    options: ReplaySignalOptions,
): Signal<T>;
export function createSignal<T = undefined>(
    options: BehaviorSignalOptions,
): Signal<T>;
export function createSignal<T = undefined>(
    options: BatchedSignalOptions,
): Signal<T, T[]>;
export function createSignal<T = undefined>(
    options: SignalOptions,
): Signal<T, T>;
export function createSignal<T = undefined>(
    options: SignalOptions = {variant: 'generic'},
): Signal<T, T[] | T> {
    const {
        allowEmittingWithoutObserver = false,
        variant = 'generic',
        name = NAMELESS_SIGNAL,
    } = options ?? {};
    type ObserverType = Observer<T> | Observer<T[]>;

    // Slots
    const observers = new Set<ObserverType>();
    const contexts = new WeakMap<
        ObserverType,
        ThisParameterType<ObserverType>
    >();
    const once = new WeakSet<ObserverType>();
    let locked = false;

    let buffers: Buffer<T | undefined> | undefined = undefined;
    switch (options?.variant) {
        case 'batched':
        case 'replay': {
            if (options.bufferSize !== undefined && options.bufferSize < 1) {
                throw new Error(
                    'Batched/Replay Signal bufferSize must be greater than 0',
                );
            }
            buffers = createBuffer<T>(
                options.bufferSize ?? DEFAULT_BUFFER_SIZE,
            );
            break;
        }
        case 'behavior': {
            buffers = createBuffer<T>(1);
            break;
        }
    }

    if (options?.variant === 'batched' && options.schedule === undefined) {
        throw new Error(
            'Batched signal requires a scheduler to be provided in the options',
        );
    }

    function emitOne(observer: ObserverType, subject?: T | T[]) {
        try {
            const context = contexts.get(observer);
            logger.trace(
                {signal: name, variant, subject, observer, context},
                `Emitting a subject to observer ${getName(observer)}`,
            );
            // biome-ignore lint/suspicious/noExplicitAny: Needs any[] to accept optional args
            observer.call<unknown, any[], void>(context, subject);
            if (once.has(observer)) {
                remove(observer);
            }
        } catch (e: unknown) {
            logger.error(
                {signal: name, variant, error: e, observer, subject},
                `emit with error for observer ${getName(observer)}`,
            );
            if (e instanceof RangeError) {
                throw new RangeError(
                    `RangeError: Possible recursive call when calling ${getName(
                        observer,
                    )} for ${name}`,
                );
            }
            throw e;
        }
    }

    const add = (
        observer: ObserverType,
        context?: ThisParameterType<ObserverType>,
    ) => {
        if (observers.has(observer)) {
            const msg = `Observer ${getName(observer)} has already been added!`;
            logger.error({signal: name, variant, observer}, msg);
            throw new Error(`DuplicatedObserver: ${msg}`);
        }
        logger.trace(
            {signal: name, variant, observer},
            `Adding ${getName(observer)} to ${name}`,
        );
        observers.add(observer);
        if (context) {
            contexts.set(observer, context);
        }

        switch (variant) {
            case 'replay':
            case 'behavior': {
                for (const subject of buffers ?? []) {
                    emitOne(observer, subject);
                }
                break;
            }
            default:
                break;
        }

        return () => remove(observer);
    };

    const addOnce = (
        observer: ObserverType,
        context?: ThisParameterType<ObserverType>,
    ) => {
        if (once.has(observer)) {
            const msg = `${getName(
                observer,
            )} has already been added once to ${name}!`;
            logger.error({signal: name, variant, observer}, msg);
            throw new Error(`NoOnceAgain: ${msg}`);
        }
        once.add(observer);
        return add(observer, context);
    };

    const remove = (observer: ObserverType) => {
        if (!observers.delete(observer)) {
            logger.error(
                {signal: name, variant, observer},
                `Unable to remove observer ${getName(observer)}`,
            );
            throw new Error(`UnableToRemove: ${getName(observer)}`);
        }
        once.delete(observer);
        contexts.delete(observer);

        logger.trace(
            {signal: name, variant, observer},
            `Removed ${getName(observer)} from ${name}`,
        );
    };

    const size = () => observers.size;

    const batchTask = () => {
        if (!buffers || buffers.size < 1) {
            return;
        }
        const result: T[] = [];
        for (const value of buffers) {
            if (value !== undefined) {
                result.push(value);
            }
        }
        buffers.clear();
        for (const observer of observers) {
            emitOne(observer, result);
        }
        locked = false;
    };

    function emit(subject?: T) {
        // Buffer the subject
        if (buffers) {
            buffers.add(subject);
        } else if (!observers.size && !allowEmittingWithoutObserver) {
            const {stack} = flags.debug ? new Error() : {stack: undefined};
            logger.warn(
                {signal: name, variant, subject, stack},
                `Emitting ${name} without any observer! This may be a mistake.`,
            );
        }
        switch (options.variant) {
            case 'batched': {
                if (!locked && buffers?.size === 1) {
                    locked = true;
                    options.schedule(batchTask);
                }
                break;
            }
            default: {
                for (const observer of observers) {
                    emitOne(observer, subject);
                }
                break;
            }
        }
    }

    return {
        name,
        get size() {
            return size();
        },
        add,
        addOnce,
        remove,
        emit,
    };
}
